body { font-family:"Helvetica Neue", Helvetica, Arial, sans-serif; }
h1 { text-align:center; font-weight:normal; }
table { width:100%; border-spacing:0; min-height:250px; }
.table-striped tr:nth-of-type(even) { background-color:#eee; }
td { padding:8px; border-top: 1px solid #ccc; }
td.category { font-weight:bold; text-align:center; width:20%; }
td.score { text-align:center; width:20%; }
td.card-info { text-align:center; }
p { text-align: center; }

button {
    background-image: linear-gradient(#42A1EC, #0070C9);
    border: 1px solid #0077CC;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    font-size: 1em;
    padding: 4px 15px;
}
button:hover {
  background-image: linear-gradient(#51A9EE, #147BCD);
  border-color: #1482D0;
  text-decoration: none;
}
button:focus {
  box-shadow: rgba(131, 192, 253, 0.5) 0 0 0 3px;
  outline: none;
}
